<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col xl="8" lg="7" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="$store.state.appConfig.coverImage"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        xl="4"
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        v-if="!showOtp"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to mySkool! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent="getOtp">
              <b-form-group label="Phone" label-for="register-phone">
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  vid="phone"
                  rules=""
                >
                  <!-- <b-form-input
                    id="register-phone"
                    v-model="phone"
                    name="register-phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="03xxxxxx"
                  /> -->
                  <vue-tel-input
                    id="register-phone"
                    v-model.trim="phone"
                    name="register-phone"
                    :inputOptions="vueTelOptions"
                    @input="CheckContact"
                  ></vue-tel-input>
                  <small class="text-danger" v-if="sPhoneerror"
                    >Phone number is required</small
                  >
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="loading"
                class="mt-2"
              >
                <span v-if="loading == false">Sign in</span>
                <div v-if="loading == true">
                  <b-spinner small variant="light" label="Spinning"></b-spinner>
                </div>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->

      <!-- verification -->
      <b-col
        xl="4"
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        v-else
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Two Step Verification 💬 </b-card-title>
          <b-card-text class="mb-2"
            >We sent a verification code to your mobile. Enter the code from the
            mobile in the field below. <br />
            ****** {{ phone.slice(-3) }}
          </b-card-text>

          <b-form class="auth-register-form mt-2" @submit.prevent="verifyOtp">
            <div class="d-flex mb-2" style="height: 55px; gap: 0.5rem">
              <b-form-input
                v-for="(item, ind) in enteredCode"
                :key="ind"
                :ref="'code' + ind"
                v-model.trim="enteredCode[ind]"
                required
                class="h-100 text-center"
                @input="moveNext(ind)"
              />
            </div>

            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="verifyToggle"
            >
              Verify
            </b-button>
          </b-form>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    // BAlert,
    //VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "admin",
      userEmail: "admin@demo.com",
      sideImg: require("@/assets/images/pages/Logo-01.png"),
      ulogin: {
        username: "",
        password: "",
      },
      // validation rules
      required,
      email,
      loading: false,
      rights: {},
      phone: "",
      showOtp: false,
      enteredCode: [],
      verifyToggle: true,
      vueTelOptions: { placeholder: "Enter contact number" },
      sPhoneerror: false,
      code: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/Logo-01.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    CheckContact(s, e) {
      if (this.phone) {
        if (e.valid) {
          this.sPhoneerror = false;
          this.phone = e.number;
          // console.log(this.phone);
        } else {
          this.sPhoneerror = true;
        }
      } else {
        this.sPhoneerror = false;
      }
    },
    getOtp() {
      // this.$refs.loginValidation.validate().then((success) => {
      //   // console.log(success);
      // });
      if (this.phone == "" || this.sPhoneerror) {
        return this.$bvToast.toast("Please enter the valid contact!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        this.code = (Math.floor(Math.random() * 900000) + 100000).toString();
        console.log(this.code);
        this.showOtp = true;
        this.enteredCode = new Array(6).fill(null);
      }
    },
    moveNext(ind) {
      if (ind != this.enteredCode.length - 1 && this.enteredCode[ind]) {
        let elem = this.$refs[`code${ind + 1}`][0];
        elem.focus();
      }
      if (this.enteredCode.includes(null) || this.enteredCode.includes("")) {
        this.verifyToggle = true;
      } else this.verifyToggle = false;
      // else this.verifyOtp();
    },
    async verifyOtp() {
      this.verifyToggle = true;
      // console.log(this.enteredCode);
      let check = this.enteredCode.join("");
      // console.log(check);
      if (check == this.code) {
        // alert("done");
        // this.verifyToggle = false;
        var config = {
          method: "post",
          url: "https://connect.myskool.app/api/Tokens/GetDbList",
          data: {
            username: this.phone.replace("+92", "0"),
            password: "*nkpy^3tJ4G8PMl$!qP453M5fZWG5cEC",
          },
        };
        try {
          var response = await axios(config);
          this.$store.commit("setDB", response.data.data[0].db);
          this.$store.commit("campusId", response.data.data[0].campusID);
          this.appLogin();
        } catch (error) {
          console.log(error);
          this.verifyToggle = false;
        }
      } else {
        this.$bvToast.toast("OTP entered is incorrect.", {
          title: "Login Falied!",
          variant: "danger",
          solid: true,
        });
        this.verifyToggle = false;
      }
    },
    appLogin() {
      var config = {
        method: "post",
        url: "https://connect.myskool.app/api/Tokens/AppLogin",
        data: {
          username: this.phone.replace("+92", "0"),
          password: "*nkpy^3tJ4G8PMl$!qP453M5fZWG5cEC",
          db: this.$store.state.userData.db,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          this.role = res.data.data.role;
          if (res.data.tID) {
            this.$store.state.userData.token = res.data.token;
            this.$store.state.userData.tID = res.data.tID;
            this.$store.state.userData.cId = res.data.data.campusID;
            this.$store.state.userData.roles = res.data.data.role;
            this.$store.state.userData.username = res.data.data.username;
            this.$store.state.userData.db = res.data.data.db;
            this.$store.state.userData.userID = res.data.data.localID;
            this.$store.state.userData.password = res.data.data.password;
            this.$store.state.userData.encPassword = res.data.data.password;

            this.$store.commit("setDomain", "https://api.myskool.app/api/");

            this.$store.commit(
              "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
              true
            );
          } else {
            this.$bvToast.toast("Something went wrong!", {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
          }
          this.verifyToggle = false;
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            solid: true,
          });
          this.verifyToggle = false;
        });
    },
    mylogin() {
      //return console.log("Heloo");
      if (this.ulogin.username !== "" && this.ulogin.password !== "") {
        this.loading = true;
        var axios = require("axios");
        var data = this.ulogin;
        var mydata = {
          username: this.ulogin.email,
          password: this.ulogin.password,
        };
        var config = {
          method: "post",
          url: "https://connect.myskool.app/api/Tokens/UserLogin",
          data: data,
        };
        axios(config)
          .then((response) => {
            // console.log(response.data);
            this.username = response.data.data.username;
            this.role = response.data.data.role;
            if (
              response.data.data.role !== "parent" &&
              response.data.data.role !== "student" &&
              response.data.data.role !== "teacher"
            ) {
              //   if (response.data.data.status == 'active') {
              // console.log("MtData", response.data);
              this.$store.state.userData.token = response.data.token;
              this.$store.state.userData.cId = response.data.data.campusID;
              this.$store.state.userData.roles = response.data.data.role;
              this.$store.state.userData.username = response.data.data.username;
              this.$store.state.userData.db = response.data.data.db;
              this.$store.state.userData.userID = response.data.data.localID;
              this.$store.state.userData.password = this.ulogin.password;
              this.$store.state.userData.encPassword =
                response.data.data.password;
              // this.$store.state.userData.userID = response.data.data.id;
              //console.log( this.$store.state.userData.token);
              // console.log("))))))))))", this.$store.state.userData);
              this.$store.commit("setDomain", "https://api.myskool.app/api/");
              // this.$store.commit(
              //   "setDomain",
              //   "https://staging.myskool.app/api/"
              // );
              this.$store.commit(
                "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
                true
              );

              var config = {
                url:
                  this.$store.state.domain +
                  "Users/GetSelected?id=" +
                  this.$store.state.userData.userID +
                  "&db=" +
                  this.$store.state.userData.db,
                headers: {
                  Authorization: "bearer " + this.$store.state.userData.token,
                },
              };
              axios(config)
                .then((res) => {
                  this.$store.commit(
                    "setMenu",
                    JSON.parse(res.data.data.forms)
                  );
                  // console.log(this.$store.state.menu);
                  this.$store.commit(
                    "setCurrentMenu",
                    this.$store.state.menu[0]
                  );
                  // console.log(this.$store.state.currentMenu);

                  this.$store.state.menu.forEach((el) => {
                    // console.log(el["icon"]);
                    el.children.forEach((ch) => {
                      this.rights[ch.route] = {
                        view: ch.view,
                        add: ch.add,
                        edit: ch.edit,
                        delete: ch.delete,
                      };
                    });
                  });
                  // console.log(this.rights);
                  this.$store.commit("setRights", this.rights);
                  // console.log(this.$store.state.rights);

                  this.loading = false;

                  //this.$router.push({ name: 'dashboard-ecommerce'})

                  // this.$router.push({ path: "/" });
                  useJwt
                    .login({
                      email: "admin@demo.com",
                      password: "admin",
                    })
                    .then((response) => {
                      // console.log(response);
                      const { userData } = response.data;
                      // console.log("UserData", userData);
                      userData.fullName = this.$store.state.userData.username;
                      userData.role = this.$store.state.userData.roles;
                      useJwt.setToken(response.data.accessToken);
                      useJwt.setRefreshToken(response.data.refreshToken);
                      localStorage.setItem(
                        "userData",
                        JSON.stringify(userData)
                      );

                      this.$ability.update(userData.ability);

                      this.$store.commit(
                        "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
                        0
                      );

                      // for page after login
                      // console.log(this.$store.state.currentMenu);
                      this.$router
                        .replace({
                          name: getHomeRouteForLoggedInUser(
                            this.$store.state.currentMenu.children[0].route
                          ),
                        })
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                              title: `Welcome Administrator`,
                              icon: "CoffeeIcon",
                              variant: "success",
                              text: `You have successfully logged in as ${this.role}. Now you can start to explore!`,
                            },
                          });
                          this.$store.commit(
                            "appConfig/UPDATE_CONTENT_WIDTH",
                            "boxed"
                          );
                        });
                    })
                    .catch((error) => {
                      this.$refs.loginForm.setErrors(error.response.data.error);
                    });
                })
                .catch((error) => {
                  // console.log(error);
                });
            } else {
              this.$bvToast.toast(
                "Please check your email or password is correct.",
                {
                  title: "Error!",
                  variant: "danger",
                  solid: true,
                }
              );
              return (this.loading = false);
            }
          })
          .catch((error) => {
            // console.log(error);
            this.$bvToast.toast(
              "Please check your email or password is correct.",
              {
                title: "Error!",
                variant: "danger",
                solid: true,
              }
            );
            this.loading = false;
          });
      } else {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      }
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
        }
      });
    },
  },
  created() {
    // console.log(this.$store.state.userData.tID);
    // console.log(this.$store.state.userData.password);
    // this.$store.commit("setDomain", "https://staging.myskool.app/api/");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
